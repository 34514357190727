<template>
  <v-container fluid id="regular-table">
    <div class="ma-3 d-flex flex-column flex-md-row" style="gap: 30px">
      <v-flex xs6>
        <h3 class="text-h4 mb-4">{{ $t("order_details") }}</h3>
        <div class="ma-4">
          <div class="text-h4 d-flex justify-space-between py-4">
            <span>{{ $t("supplyOrders.shelf") }}</span>
            <span class="text-h4">
              {{ supplyOrder?.shelf?.name ?? "-" }}
            </span>
          </div>
          <v-divider></v-divider>
          <div
            class="text-h4 d-flex justify-space-between py-4"
            style="min-width: 200px"
          >
            <span>{{ $t("supplyOrders.supplier") }}</span>
            <span>{{ supplyOrder?.fromProvider?.name }}</span>
          </div>
          <v-divider></v-divider>
          <div
            class="text-h4 d-flex justify-space-between py-4"
            style="min-width: 200px"
          >
            <span>{{ $t("inbound_id") }}</span>
            <router-link
              v-if="$admin.can('inbound-view')"
              :to="{
                name: 'inbound.products.show',
                params: { id: this.inbound?.id },
              }"
            >
              <span>{{ this.inbound?.tracking_number }}</span>
            </router-link>
          </div>
          <v-divider></v-divider>
          <div
            class="text-h4 d-flex justify-space-between py-4"
            style="min-width: 200px"
          >
            <span class="mt-2">{{ $t("purchase_order") }}</span>
            <v-btn
              :loading="isLoading"
              rounded
              class="primary"
              text
              @click="downloadItem(supplyOrder)"
            >
              Purchase Order.pdf
              <v-icon class="ma-2"> mdi-download </v-icon>
            </v-btn>
          </div>
          <v-divider></v-divider>
          <div v-if="supplyOrder.supply_order_status == this.cancelledStatus">
            <div
              class="text-h4 d-flex justify-space-between py-4"
              style="min-width: 200px"
            >
              <span>{{ $t("supplyOrders.reason_cancellation") }}</span>
              <span>{{ supplyOrder.reason_cancellation }}</span>
            </div>
            <v-divider></v-divider>
            <div
              class="text-h4 d-flex justify-space-between py-4"
              style="min-width: 200px"
            >
              <span>{{ $t("supplyOrders.cancelled_by") }}</span>
              <span>{{ supplyOrder.cancelled_by }}</span>
            </div>
            <v-divider></v-divider>
          </div>

          <br />
          <br />

          <h3 class="text-h4 mb-4 ma-n3">
            {{ $t("supplyOrders.delivery_date") }}
          </h3>
          <div class="mt-5">
            <div
              class="text-h4 d-flex justify-space-between py-4"
              style="min-width: 200px"
            >
              <span class="text-h4">
                <v-icon> mdi-calendar </v-icon>
                {{ supplyOrder.expected_delivery_date }}
                <span
                  v-if="supplyOrder.delivery_date_last_edited_at"
                  class="text--disabled ma-15"
                  >Last edited : {{ supplyOrder.delivery_date_last_edited_at }}
                </span>

                <span
                  class="text-decoration-underline primary--text"
                  v-if="supplyOrder.history && showDetails"
                  >{{ $t("hide_details") }}
                </span>
                <v-icon
                  class="primary--text"
                  @click="hide()"
                  v-if="supplyOrder.history && showDetails"
                  >mdi-chevron-down</v-icon
                >

                <span
                  class="text-decoration-underline primary--text"
                  v-if="!showDetails"
                  >{{ $t("show_details") }}
                </span>
                <v-icon
                  class="primary--text"
                  @click="show()"
                  v-if="!showDetails"
                  >mdi-chevron-up</v-icon
                >
              </span>
            </div>
            <v-divider
              v-if="!supplyOrder.delivery_date_last_edited_at || !showDetails"
            ></v-divider>
          </div>
          <v-data-table
            v-if="supplyOrder.history && showDetails"
            :items="supplyOrder.history"
            :headers="date_headers"
            item-key="id"
            class="elevation-0"
            :hide-default-footer="true"
          >
          </v-data-table>
        </div>
      </v-flex>
      <v-flex xs6>
        <h3 class="text-h4 mb-4">{{ $t("supplyOrders.delivery_details") }}</h3>
        <div class="ma-4">
          <div
            class="text-h4 d-flex justify-space-between py-4"
            style="min-width: 200px"
          >
            <span>{{ $t("supplyOrders.delivery_type") }}</span>
            <span class="text-h4">{{
              supplyOrder?.delivery_type?.toUpperCase() ?? "-"
            }}</span>
          </div>
          <v-divider></v-divider>

          <div
            class="text-h4 d-flex justify-space-between py-4"
            style="min-width: 200px"
          >
            <span>{{ $t("supplyOrders.warehouse") }}</span>
            <span class="text-h4">{{ supplyOrder?.toHub?.name }}</span>
          </div>
          <v-divider></v-divider>
        </div>
      </v-flex>
    </div>
    <div>
      <card
        flat
        v-if="
          supplyOrder.supply_order_status != receivedStatus &&
          supplyOrder.supply_order_status != partiallyReceivedStatus
        "
      >
        <v-card-title class="text-h4"
          >Item List ({{ supplyOrder.products_count }})</v-card-title
        >
        <br />
        <div class="ms-5">
          <v-data-table
            :items="
              supplyOrder.products?.map((product) => {
                return {
                  ...product,
                  total_price_ttc: Number(product.total_price)?.toFixed(2),
                };
              })
            "
            :headers="headers"
            item-key="id"
            class="elevation-0"
          >
            <template v-slot:item.image="{ item }">
              <v-img
                v-if="item.image"
                :src="item.image.url"
                max-height="50"
                max-width="50"
              />
              <v-img
                v-else
                src="@/assets/product.png"
                max-height="50"
                max-width="50"
              />
            </template>
            <template v-slot:item.purchase_price="{ item }">
              {{
                Number(
                  (item.purchase_price_ht -
                    item.purchase_price_ht * (item.discount / 100)) *
                    (1 + parseFloat(item.purchase_tva) / 100)
                )?.toFixed(2)
              }}
            </template>
            <template v-slot:item.discount_price="{ item }">
              <span v-if="item.discount != 0">
                {{
                  Number(
                    item.purchase_price_ht -
                      item.purchase_price_ht * (item.discount / 100)
                  ).toFixed(2)
                }}
              </span>
              <span v-else> - </span>
            </template>
            <template v-slot:item.discount="{ item }">
              <span>{{ item.discount ?? "-" }}</span>
            </template>
            <template v-slot:item.total_units="{ item }">
              {{ calculateUnits(item) }}
            </template>
            <template slot="body.append">
              <tr>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th class="text-h4">{{ $t("total_amount") }}</th>
                <th class="text-h4">
                  {{ sumField("total_price")?.toFixed(2) }}
                </th>
              </tr>
            </template>
          </v-data-table>
        </div>
      </card>

      <div class="mt-8 d-flex">
        <v-btn class="ml-auto primary" text @click="close()"> Back </v-btn>
      </div>
    </div>
    <update-delivery-date
      :isUpdateDateModalOpen="isUpdateDateModalOpen"
      :toggleUpdateDate="toggleUpdateDate"
      :currentOrderItem="supplyOrder"
    ></update-delivery-date>
  </v-container>
</template>
<script>
import { mapGetters } from "vuex";
import supplyOrderService from "@/store/services/supply-order-service";

export default {
  components: {
    UpdateDeliveryDate: () => import("./modals/UpdateDeliveryDate.vue"),
  },
  computed: {
    inbound() {
      return this.supplyOrder.inbounds?.[0];
    },
    ...mapGetters({
      supplyOrder: "supplyOrders/supplyOrder",
    }),
  },

  data() {
    return {
      isLoading: false,
      showDetails: true,
      cancelledStatus: "cancelled",
      receivedStatus: "received",
      partiallyReceivedStatus: "received partially",
      isUpdateDateModalOpen: false,

      headers: [
        { text: "Image", value: "image", align: "start" },
        {
          text: "Produits",
          sortable: false,
          value: "name",
        },
        { text: "Quantity (Units)", value: "total_units" },
        { text: "Purchase price HT", value: "purchase_price_ht" },
        { text: "Discount %", value: "discount" },
        { text: "Discount Price", value: "discount_price" },
        { text: "VAT %", value: "purchase_tva" },
        { text: "Purchase Price", value: "purchase_price" },
        { text: "Total price", value: "total_price_ttc" },
      ],
      date_headers: [
        { text: "Previous date", value: "previous_date" },
        { text: "Edit date", value: "edit_date" },
        { text: "Modifie by", value: "modified_by" },
      ],
    };
  },

  methods: {
    sumField(key) {
      return this.supplyOrder.products?.reduce((a, b) => a + (b[key] || 0), 0);
    },
    async downloadItem(supplyOrder) {
      this.isLoading = true;
      const BDC = await supplyOrderService.getInvoice(supplyOrder);
      const url = window.URL.createObjectURL(new Blob([BDC]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${supplyOrder.tracking_number}.pdf`);
      document.body.appendChild(link);
      link.click();
      this.isLoading = false;
    },

    hide() {
      this.showDetails = false;
    },
    show() {
      this.showDetails = true;
    },
    toggleUpdateDate() {
      this.isUpdateDateModalOpen = !this.isUpdateDateModalOpen;
    },
    updateDate() {
      this.toggleUpdateDate();
    },
    close() {
      this.$router.push("/supply-orders");
      this.$store.commit("SET_PAGE_SUBTITLE", "");
      this.$store.commit("SET_SUBTITLE_ID", "");
    },

    calculateUnits(item) {
      switch (item.type_packaging) {
        case "unit":
          return item.qte_by_type_packaging;
        case "pack":
          return Number(
            item.qte_by_type_packaging * item.product_info.package_units
          );
        case "pallet":
          return Number(
            item.qte_by_type_packaging *
              item.product_info.package_units *
              item.product_info.palette_units
          );
        default:
          return 1;
      }
    },
  },

  async created() {
    const id = this.$route.params.id;
    if (id) {
      const supplyOrderEntity = await supplyOrderService.show({
        id,
      });
      this.$store.commit("supplyOrders/SupplyOrder", supplyOrderEntity);
      this.$store.commit(
        "SET_PAGE_SUBTITLE",
        supplyOrderEntity.tracking_number
      );
      this.$store.commit("SET_SUBTITLE_ID", "Order ID :");
    }
  },
};
</script>